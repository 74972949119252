// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/
@media all {
  /* Never show page break in normal view */
  .page-break {
    display: none;
  }
}
@media print {
  /* Page Styles */
  @page {}
  /* Page break */
  .page-break {
    display: block;
    break-after: always;
    page-break-after: always;
  }
  /* General styles */
  #main #vertical-navigation {
    display: none;
  }
  #main #toolbar {
    display: none;
  }
  #main #quick-panel {
    display: none;
  }
  #main #content {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    background: none !important;
  }
  ms-theme-options {
    display: none;
  }
  .ps-scrollbar-x-rail,
  .ps-scrollbar-y-rail {
    display: none !important;
  }
  /* Printable page specific styles */
  .printable {
    overflow: visible !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/core/scss/partials/print.scss"],"names":[],"mappings":"AAAA,mEAAA;AACA;mEAAA;AAGA;EAEI,yCAAA;EACA;IACI,aAAA;EADN;AACF;AAKA;EAEI,gBAAA;EACA,OAAA;EAIA,eAAA;EACA;IACI,cAAA;IACA,mBAAA;IACA,wBAAA;EAPN;EAUE,mBAAA;EAGI;IACI,aAAA;EAVV;EAaM;IACI,aAAA;EAXV;EAcM;IACI,aAAA;EAZV;EAeM;IACI,6BAAA;IACA,iBAAA;IACA,mBAAA;IACA,oBAAA;IACA,kBAAA;IACA,2BAAA;EAbV;EAiBE;IACI,aAAA;EAfN;EAkBE;;IAEI,wBAAA;EAhBN;EAmBE,mCAAA;EACA;IACI,4BAAA;EAjBN;AACF","sourcesContent":["/*----------------------------------------------------------------*/\r\n/*  Print\r\n/*----------------------------------------------------------------*/\r\n\r\n@media all {\r\n\r\n    /* Never show page break in normal view */\r\n    .page-break {\r\n        display: none;\r\n    }\r\n\r\n}\r\n\r\n@media print {\r\n\r\n    /* Page Styles */\r\n    @page {\r\n        //margin: 0.5cm;\r\n    }\r\n\r\n    /* Page break */\r\n    .page-break {\r\n        display: block;\r\n        break-after: always;\r\n        page-break-after: always;\r\n    }\r\n\r\n    /* General styles */\r\n    #main {\r\n\r\n        #vertical-navigation {\r\n            display: none;\r\n        }\r\n\r\n        #toolbar {\r\n            display: none;\r\n        }\r\n\r\n        #quick-panel {\r\n            display: none;\r\n        }\r\n\r\n        #content {\r\n            position: relative !important;\r\n            top: 0 !important;\r\n            right: 0 !important;\r\n            bottom: 0 !important;\r\n            left: 0 !important;\r\n            background: none !important;\r\n        }\r\n    }\r\n\r\n    ms-theme-options {\r\n        display: none;\r\n    }\r\n\r\n    .ps-scrollbar-x-rail,\r\n    .ps-scrollbar-y-rail {\r\n        display: none !important;\r\n    }\r\n\r\n    /* Printable page specific styles */\r\n    .printable {\r\n        overflow: visible !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
