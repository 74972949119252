import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { InitialisationService } from '@services/initialisation.service';

// Override Angular's default '$exceptionHandler' mechanism to allow sending exceptions to BugSnag.
angular.module('fuse').factory('$exceptionHandler',
  ($log: angular.ILogService) => {
    return (exception, _cause) => {
      // Send the error to BugSnag (if client configured).
      InitialisationService.BugSnagClient?.notify(exception);

      // Log the error to the console as well.
      $log.error(exception);
    };
});

angular.module('fuse').config([
  '$mdDateLocaleProvider',
  ($mdDateLocaleProvider: angular.material.IDateLocaleProvider) => {
    $mdDateLocaleProvider.formatDate = (date: Date): string => {
      return DateUtils.Locale.asDateDefault(date);
    };
  },
]);

angular.module('fuse').config([
  '$locationProvider',
  ($locationProvider: angular.ILocationProvider) => {
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: true,
    });
  },
]);

angular.module('fuse').config([
  '$httpProvider',
  ($httpProvider: angular.IHttpProvider) => {
    $httpProvider.interceptors.push('AuthInterceptorService');
  },
]);

angular.module('fuse').config([
  '$mdAriaProvider',
  ($mdAriaProvider) => {
    return $mdAriaProvider.disableWarnings();
  },
]);

/*
angular.module('fuse').config([
  'applicationInsightsServiceProvider',
  (applicationInsightsServiceProvider) => {
    applicationInsightsServiceProvider.configure({
      instrumentationKey: AppSettings.appInsights.instrumentationKey,
      applicationName: 'swan-front',
      autoPageViewTracking: false,
      autoStateChangeTracking: false,
      autoLogTracking: false,
      autoExceptionTracking: true,
      disableAjaxTracking: true,
    });
  },
]);
*/
